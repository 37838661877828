// src/SquaresApp.js
import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';

// const apiBaseUrl = 'http://localhost:5000/api';
const apiBaseUrl = 'https://testserver-coral.vercel.app/api';
const allSquaresUrl = `${apiBaseUrl}/all-squares`;
const checkOutUrl = `${apiBaseUrl}/checkout`;
const selectedSquaresUrl = `${apiBaseUrl}/selected-squares`;

function SquaresApp() {
    const [selectedSquares, setSelectedSquares] = useState([]);
    const [squares, setSquares] = useState([]);
    const [load, setLoad] = useState('0%');
    const [isData, setData] = useState(false);
    const [checkOutLoading, setCheckOutLoading] = useState(false);

    useEffect(() => {
        setLoad(`${selectedSquares.length}%`);
    }, [selectedSquares.length]);

    useEffect(() => {
        fetchSelectedSquares();
        // Other useEffect logic
    }, []);

    const fetchSelectedSquares = async () => {
        setData(true)
        try {
            const response = await axios.get(selectedSquaresUrl);
            setSelectedSquares(response.data.map(square => square.squareId));
            setLoad(`${response.data.map(square => square.squareId).length}%`)
            setData(false)
        } catch (error) {
            console.error('Error fetching selected squares:', error);
            setData(false)
        }
    };







    const [cart, setCart] = useState([]);
    const [cartSelectedBy, setSelectedBy] = useState([]);
    const selectSquare = async (squareId) => {
        try {
            // Check if the square is already selected in the local cart
            const index = selectedSquares.indexOf(squareId);
            if (index !== -1) {
                alert('Square already selected');
                return;
            }

            if (cart.includes(squareId)) {
                alert('Already added to the cart')
                return
            }

            const selectedBy = prompt('Enter your name:');
            if (!selectedBy) return;

            // Add the selected square to the local cart
            setCart([...cart, squareId]);
            setSelectedBy([...cartSelectedBy, selectedBy])
            // Optionally, update the UI to reflect the selected squares in the cart
            // ...

        } catch (error) {
            console.error('Error selecting square:', error);
            window.alert('Error selecting square:"Already selected"');
            // Handle the error appropriately, e.g., show an error message to the user
        }
    };
    console.log(cart)
    console.log(cartSelectedBy)
    // ... (other functions remain unchanged)

    useEffect(() => {
        setLoad(`${selectedSquares.length}%`)
        // console.log(`${selectedSquares.length * 30 / 10} %`)
    }, [selectedSquares.length])

    //











    const checkout = async () => {
        if (cart.length === 0) {
            alert('Please select some squares');
            return;
        }
        setCheckOutLoading(true);
        const priceData = {
            price: cart.length * 30,
            cart,
            cartSelectedBy
        }

        // Initiate checkout session with Stripe
        const response = await axios.post(checkOutUrl, { priceData });

        window.location.href = response.data.url;
        setCheckOutLoading(false)
    };







    useEffect(() => {
        // Fetch data from the server (replace 'your-api-endpoint' with the actual endpoint)
        fetch(allSquaresUrl)
            .then(response => response.json())
            .then(data => setSquares(data))
            .catch(error => console.error('Error fetching squares:', error));
    }, []);


    const generateRows = () => {
        const rows = [];
        const rowLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

        for (const rowLabel of rowLabels) {
            const cells = squares
                .filter(square => square.squareId.startsWith(rowLabel))
                .map(square => (
                    <td key={square.squareId} id={square.squareId} className={square.isSelected ? 'selected' : ''}>
                        <a style={{ cursor: 'pointer' }} onClick={() => selectSquare(square.squareId)}>
                            {square.isSelected ? `${square.selectedBy}'s Square` : '$30'}
                        </a>
                    </td>
                ));


            rows.push(
                <tr key={rowLabel}>
                    <th scope="row">{rowLabel}</th>
                    {cells}
                </tr>
            );
        }

        return rows;
    };



    return (
        <div className="squares-app">
            <div id="header">
                <div id="mast">
                    <h1>MD Express Fundraisers</h1>
                    <h2>2024 Superbowl Squares</h2>
                </div>
                <nav id="navbar">
                    <ul>
                        <li><a href="/" class="active">The Grid</a></li> 
                        <li><a href="#about">How does this work?</a></li>
                        <li><a href="#prizes">Prizes</a></li>
                    </ul>
                </nav>
                <h1>Click a square it to purchase it!</h1>
                <div id="cart">
                    <h1>Your Cart</h1>
                    <h3>Selected Square</h3>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', maxHeight: '50px', overflowY: 'auto', justifyContent: 'center', alignItems: 'center' }}>
                        {cart.map((data, index) => (
                            <p style={{ background: 'black', color: 'white', padding: '5px', borderRadius: '10px', margin: '4px' }} key={index}>{data}</p>
                        ))}
                    </div>
                    <p style={{ fontWeight: 'bold' }} id="empty">Total: {cart.length === 0 ? 'Nothing Yet' : `$${cart.length * 30}`}</p>
                    <button style={{ background: '#6cc83f', border: '5px solid black', padding: '5px', borderRadius: '5px', fontWeight: 'bold', cursor: 'pointer', }} onClick={checkout}>{checkOutLoading ? "Wait.." : 'Check Out'}  </button>
                </div>
            </div>
            <div style={{ marginTop: "140px" }} id="thermometer">
                <div style={{ width: `${load}` }} id="raised" data-value="0">
                    {
                        selectedSquares.length === 0 ? '$0' :
                            `$${selectedSquares.length * 30}`
                    }
                </div>
                <div id="tip"></div>
                <div id="goal">
                    <p>Goal:<br /><strong>$3,000</strong></p>
                </div>
            </div>
            <div id="main">
                {
                    isData ? <h1>LOADING...</h1>
                        :
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th scope="col">A</th>
                                    <th scope="col">B</th>
                                    <th scope="col">C</th>
                                    <th scope="col">D</th>
                                    <th scope="col">E</th>
                                    <th scope="col">F</th>
                                    <th scope="col">G</th>
                                    <th scope="col">H</th>
                                    <th scope="col">I</th>
                                    <th scope="col">J</th>
                                </tr>
                            </thead>
                            <tbody>{generateRows()}</tbody>
                        </table>
                }
            </div>
            <div id="about">
            <h1>Welcome to My100Squares.com!</h1>
            <p><a href="/">Back to Top</a></p>
            <p>We're excited to bring you a fun and engaging way to participate in one of the biggest sporting events of the year while supporting a great cause. My100Squares.com is dedicated to raising funds for the Maryland Express 13U Softball Team. Our goal? To hit a home run with a fundraising target of $3,000!</p>

            <h2>Who We Are Supporting</h2>
            <p>The Maryland Express 13U Softball Team is a dynamic and talented group of young athletes committed to excellence both on and off the field. They exemplify teamwork, dedication, and the spirit of fair play. This fundraiser will help cover costs like equipment, uniforms, travel expenses, and tournament fees, ensuring these young stars have everything they need to succeed.</p>

            <h2>How Our Super Bowl Grid Works</h2>
            <p>Join the excitement of the Super Bowl and have a chance to win with our Super Bowl grid!</p>
            <ul>
                <li><strong>The Grid:</strong> Our 10x10 grid creates 100 squares, each available for purchase at $30. This simple, luck-based game is perfect for both football enthusiasts and casual fans.</li>
                <li><strong>Buying Squares:</strong> Select and buy your preferred squares. You can buy as many as you like until all 100 are sold. Remember, each square purchased gets us closer to our fundraising goal!</li>
                <li><strong>Random Numbers Assignment:</strong> After all the squares are sold, we will randomly assign numbers 0-9 to each row and column. These numbers correspond to the last digit of the score for each team playing in the Super Bowl.</li>
                <li><strong>Winning:</strong> Winners are determined at the end of each quarter based on the current score. If the last digit of each team's score matches the numbers on your square, you win! Prizes will be awarded for the scores at the end of each quarter, with a larger prize for the final score.</li>
            </ul>
            <h2>Join the Fun and Support a Great Cause</h2>
            <p>Your participation in our Super Bowl grid is more than just a chance to win – it's an opportunity to make a real difference in the lives of these young athletes. Let's rally together for the Maryland Express 13U Softball Team and show them what teamwork and community support can achieve!</p>
            <p>Thank you for your support, and here's to a game-winning fundraiser!</p>
        </div>
        <div id="prizes">
                <h1>Prizes</h1>
                <p><a href="/">Back to Top</a></p>
                <p>We've selected a pricing model that balances prizes and donations to the team.</p>
                <h2>Quarter 1</h2>
                <p>At the end of quarter 1, the person with the square with the exact last two digits of each teams score will win <strong>$100</strong></p>
                <h2>Quarter 2</h2>
                <p>At the end of quarter 2, the person with the square with the exact last two digits of each teams score will win <strong>$100</strong></p>
                <h2>Quarter 3</h2>
                <p>At the end of quarter 3, the person with the square with the exact last two digits of each teams score will win <strong>$200</strong></p>
                <h2>Quarter 4 - Final</h2>
                <p>At the end of the game, the person with the square with the exact last two digits of each teams score will win <strong>$500</strong></p>
                <h2>Donation to the Team</h2>
                <p>This means a total of $1,000 in prizes and $2,000 remaining funds will be donated to the Maryland Express 13U Softball Team.</p>
            </div>
            </div>
    );
}

export default SquaresApp;
