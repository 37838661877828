import React from 'react';

const Success = () => {
    return (
            <div id="success">
                <h1>Thank You for Your Support!</h1>
                <p>Your purchase has been successfully completed. We are thrilled to have your support for the Maryland Express 13U Softball Team. Your contribution is making a significant difference!</p>
        
                <h2>What's Next?</h2>
                <p>We've received your square selection for the Super Bowl grid. Here's what happens now:</p>
                <ul>
                    <li>Your selected squares are now reserved under your name.</li>
                    <li>We'll randomly assign numbers to the grid once all squares are sold and notify you of your numbers before the Super Bowl.</li>
                    <li>Keep an eye on the game! We'll announce winners at the end of each quarter and the final score.</li>
                </ul>
        
                <h2>Stay Updated</h2>
                <p>Keep up with the latest updates and announcements related to the fundraiser and the Maryland Express 13U Softball Team. Follow us on [Social Media Link] and check back on our website.</p>
        
                <h2>Share with Friends and Family</h2>
                <p>Want to increase your chances of winning or help us reach our fundraising goal faster? Share this fun opportunity with your friends and family! Let's make this event a grand slam for our team.</p>
        
                <p>Once again, thank you for your generosity and support. Your contribution is much more than a square on a grid – it's a step towards achieving dreams and building a strong community.</p>
        
                <p><a href="index.html">Return to the Grid</a></p>
            </div>
    );
};

export default Success;