import SquaresApp from "../SquaresApp";
import Success from "../Success";

const { createBrowserRouter } = require("react-router-dom");

export const route = createBrowserRouter([
    {
        path: '/',
        element: <SquaresApp></SquaresApp>,
    },
    {
        path: '/success',
        element: <Success></Success>,
    },
])